<template>
  <div class="sideAdvertisingSpace_box" v-if="extensionImg" @click="toHotSeason" :style="{top: setTop + 'px'}">
    <img :src="extensionImg" alt="">
    <p>{{ $t('key1000407') }}</p>
    <p style="font-weight: bold">{{ $t('key1000408') }}</p>
  </div>
</template>

<script>

import Mixin from "@/components/mixin/common_mixin";
import {advertisingLinkAddress, commonSessionStorage} from "@/utils/common";

export default {
  name: "sideAdvertisingSpace",
  mixins: [Mixin],
  props: {
    setTop: {
      type: Number,
      default: 205
    }
  },
  data() {
    return {
      jumpTargetObj: null
    }
  },
  computed: {
    extensionImg() {
      let mallAdvertising = commonSessionStorage.getItem('mallAdvertising');
      let advertisingList = this.$store.state.mallAdvertising.length > 0 ? this.$store.state.mallAdvertising : mallAdvertising;
      let imagePath = '';
      if (advertisingList && advertisingList.length > 0) {
        advertisingList.map((item) => {
          if (item.orientationType === 3) {
            if (item.status) {
              imagePath = item.ymsAdvertisementDetailBos[0].imagePath;
              this.jumpTargetObj = item.ymsAdvertisementDetailBos[0];
            }
          }
        });
      }
      return imagePath;
    },
    // 是否固定侧边栏广告
    navBarFixed() {
      return this.$store.state.navBarFixed;
    }
  },
  methods: {
    // 点击侧边栏广告
    toHotSeason() {
      let v = this;
      advertisingLinkAddress(v.jumpTargetObj);
    }
  }
}
</script>

<style lang="less" scoped>

.sideAdvertisingSpace_box {
  background-color: #fff;
  width: 150px;
  position: absolute;
  top: 205px;
  left: 190px;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
  padding-bottom: 6px;
  font-size: 13px;
  color: #333;
  overflow: hidden;
  z-index: 99;

  &:hover {
    box-shadow: 0 0 6px #787878;
  }
}

.fixed_sideAdvertisingSpace_box {
  position: fixed;
  top: 175px !important;
  left: 190px;
}

@media screen and (max-width: 1370px) {
  .sideAdvertisingSpace_box {
    display: none;
  }
}
</style>
